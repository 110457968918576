<template>
    <button @click="goBackClick" class="flex gap-2.5 items-center cursor-pointer hover:text-primary">
        <ElementsIcon icon="carretRight" />
        <slot>Zurück</slot>
    </button>
</template>
<script setup>
const props = defineProps({
    auto: { type: Boolean, default: false },
})
const emits = defineEmits(['goBack'])

const goBackClick = () => {
    if(props.auto){
        useGoBack()
    }
    emits('goBack')
}
</script>